<template>
  <transition name="modal">
    <div class="modal-mask" @click.self="$emit('close')">
      <div :class="['modal-wrapper', width]">
        <div class="modal-header">
          <slot name="header">default header</slot>
          <button class="modal-close" @click="$emit('close')">&times;</button>
        </div>
        <div class="modal-body">
          <slot name="body">default body</slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'modal',
  props: {
    width: {
      type: String,
      default: ''
    }
  }
};
</script>

<style>
.modal-mask {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 0.3s ease;
  overflow-y: auto;
  padding: 15px;
  z-index: 1031;
}

.modal-wrapper {
  width: 100%;
  padding: 80px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  position: relative;
  margin: auto;
}

.modal-wrapper.full {
  width: 80%;
}

.modal-wrapper.small {
  max-width: 780px;
}

.modal-header {
  margin-bottom: 40px;
}

.modal-close {
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 45px;
  right: 45px;
  background: url(../assets/img/icon-close.svg) center no-repeat;
  background-size: contain;
  z-index: 1;
}

.modal-close:hover {
  opacity: .7;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-wrapper,
.modal-leave-active .modal-wrapper {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>