<template>
  <div>
    <div id="side-bar" :class="{visible}">
      <div class="side-bar-loading" v-if="loadend">
        <Loader/>
      </div>
      <a href="javascript:void(0)" class="close-btn" @click="this.hideSideBar"/>
      <div class="side-bar-body ambulance-info">
        <span class="ambulance-name">{{ this.info.legal_entity_name }}</span>
        <ul class="ambulance-description">
          <li><strong>Вакциновано:</strong><span><span class="text-with-background"><strong>{{ this.info.total_vaccinated }}</strong></span></span></li>
          <li><strong>Можливо втрачено:</strong><span><span class="text-with-background"><strong>{{ this.info.FUCKEDUP }}</strong></span></span></li>
          <li><strong>ID:</strong><span class="ambulance-id">{{ this.info.legal_entity_id }}</span></li>
          <li><strong>Адреса:</strong><span>{{ this.info.registration_addresses }}</span></li>
          <li><strong>Тип:</strong><span>{{ this.info.property_type }}</span></li>
          <li><strong>Керівник:</strong><span>{{ this.info.legal_entity_owner_name }}</span></li>
          <li><strong>ЄДРПОУ:</strong><span>{{ this.info.legal_entity_edrpou }}</span></li>
          <li><strong>Email:</strong><span><a :href="`mailto:${this.info.legal_entity_email}`">{{ this.info.legal_entity_email }}</a></span></li>
          <li><strong>Телефон:</strong><span><a :href="`tel:${this.info.legal_entity_phone}`">{{this.info.legal_entity_phone }}</a></span></li>
        </ul>
        <div class="ambulance-chart" ref="chart"/>
      </div>
      <div class="side-bar-foot">
        <button class="btn btn-small btn-hover-effect" @click="openModal">Повідомити про порушення</button>
      </div>
    </div>
    <modal v-if="showModal" @close="closeModal" width="small">
      <h3 slot="header"><span>Повідомити про порушення</span></h3>
      <div slot="body">
        <ComplaintForm :info="info" @form-close="closeModal"/>
      </div>
    </modal>
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_uk_UA from "@amcharts/amcharts4/lang/uk_UA";
import Loader from "./Loader";
import ComplaintForm from "./ComplaintForm";
import modal from "./modal";

am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;

export default {
  name: "Sidebar",
  components: {
    Loader,
    ComplaintForm,
    modal
  },
  props: ["info", "visible", "loadend"],
  data() {
    return {
      chart: null,
      chartData: [],
      showModal: false
    }
  },
  watch: {
    info(a) {
      if (Object.prototype.hasOwnProperty.call(a, 'stat') && a.stat.length !== 0) {
        const chartData = [];
        const ambulanceStat = this.info.stat;
        for (let key in ambulanceStat) {
          if (Object.prototype.hasOwnProperty.call(ambulanceStat, key)) {
            chartData.push({
              "year": key,
              "total": ambulanceStat[key].TOTAL,
              "fuckedup": ambulanceStat[key].TOTAL_FUCKEDUP
            });
          }
        }
        // Add data
        this.chart.data = chartData;
      }
    }
  },
  mounted() {
    // Create chart instance
    this.chart = am4core.create(this.$refs.chart, am4charts.XYChart);
    this.chart.language.locale = am4lang_uk_UA;
    this.chart.paddingLeft = 0;

    // Create axes
    let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 65;
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.cellStartLocation = 0.1;
    dateAxis.renderer.cellEndLocation = 0.9;

    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;

    // Create series
    function createSeries(field, name, color, chart) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = "year";
      series.fill = am4core.color(color);
      series.columns.template.strokeOpacity = 0;
      series.minBulletDistance = 10;

      series.name = name;
      series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
      series.stacked = true;
      series.columns.template.width = am4core.percent(95);
    }

    createSeries("total", "Вакциновано", '#8687ff', this.chart);
    createSeries("fuckedup", "Можливо втрачено", '#dc67ab', this.chart);

    // Add vertical scrollbar
    this.chart.scrollbarX = new am4core.Scrollbar();
    this.chart.scrollbarX.marginLeft = 0;

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor();
    this.chart.cursor.xAxis = dateAxis;
  },
  methods: {
    hideSideBar() {
      this.$emit('close-sidebar');
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    }
  }
}
</script>