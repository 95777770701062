<template>
  <div id="map">
    <l-map
        ref="map"
        :zoom="zoom"
        :minZoom="minZoom"
        :maxZoom="maxZoom"
        :center="center"
        :options="mapOpt"
        @update:zoom="zoomUpdate"
        @update:center="centerUpdate"
        @update:bounds="boundsUpdate"
    >
      <l-tile-layer
          :url="url"
          :attribution="attribution"
          :tms="tms"
          :subdomains="subdomains"
      />
      <l-control-zoom position="bottomright"/>
      <v-locatecontrol :options="locateOpt"/>
      <v-marker-cluster>
        <l-marker
            v-for="marker in markers"
            :lat-lng="[marker.lat, marker.lng]"
            :icon="icon"
            :key="marker.legal_entity_id"
            @click="markerHandler(marker.legal_entity_id)"
        />
      </v-marker-cluster>
    </l-map>
    <sidebar
        :info="ambulanceInfo"
        :visible="visibleSideBar"
        :loadend="loadend"
        @close-sidebar="hideSideBar"
    />
  </div>
</template>

<script>
import {latLng, icon} from "leaflet";
import {LMap, LTileLayer, LControlZoom, LMarker} from "vue2-leaflet";
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import Vue2LeafletLocatecontrol from 'vue2-leaflet-locatecontrol/Vue2LeafletLocatecontrol'
import sidebar from "@/components/Sidebar";

import marker from '../assets/img/marker-icon.svg';
import {API_URL, MAP_KEY} from "@/config";

export default {
  name: "Map",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlZoom,
    sidebar,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    'v-locatecontrol': Vue2LeafletLocatecontrol
  },
  data() {
    let mapZoom = localStorage.getItem('map_zoom') || 8;
    let mapCenter = latLng(50.4500336, 30.5241361);
    if (localStorage.getItem('map_center')) {
      const {lat, lng} = JSON.parse(localStorage.getItem('map_center'));
      mapCenter = latLng(lat, lng);
    }
    return {
      center: mapCenter,
      zoom: parseInt(mapZoom),
      minZoom: 6,
      maxZoom: 18,
      url: `https://tms{s}.visicom.ua/2.0.0/world,ua/base/{z}/{x}/{y}.png?key=${MAP_KEY}`,
      attribution: `&copy; ${new Date().getFullYear()} <a href="https://api.visicom.ua/" target="_blank">Візіком</a>`,
      tms: true,
      subdomains: "123",
      icon: icon({
        iconUrl: marker,
        iconSize: [42, 47],
        iconAnchor: [21, 47],
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        shadowAnchor: [12, 43]
      }),
      markers: [],
      selectedMarker: null,
      visibleSideBar: false,
      ambulanceInfo: {},
      loadend: false,
      locateOpt:{
        position:'bottomright',
        strings: {
          title: "Знайти мене на карті"
        }
      },
      mapOpt: {
        zoomControl: false
      }
    };
  },
  mounted() {
    const {_southWest, _northEast} = this.$refs.map.mapObject.getBounds();
    this.getMarkers(_southWest, _northEast);

    const id = this.$route.query.id;
    if (id) {
      this.markerHandler(id).then(() => {
        this.$refs.map.mapObject.setView([this.ambulanceInfo.lat, this.ambulanceInfo.lng], this.maxZoom);
      });
    }
  },
  methods: {
    zoomUpdate(zoom) {
      localStorage.setItem('map_zoom', zoom);
    },
    centerUpdate(center) {
      localStorage.setItem('map_center', JSON.stringify(center));
    },
    boundsUpdate({_southWest, _northEast}) {
      this.getMarkers(_southWest, _northEast);
    },
    markerHandler: async function (id) {
      if (this.selectedMarker !== id) {
        this.$router.push({query: {id: id}});
        this.loadend = true;
        this.selectedMarker = id;
        this.showSideBar();
        await this.getAmbulanceInfo(id);
      }
    },
    showSideBar() {
      this.visibleSideBar = true;
    },
    hideSideBar() {
      this.visibleSideBar = false;
      this.selectedMarker = null;
      this.$router.push({path: '/'});
    },
    getMarkers(southWest, northEast) {
      fetch(`${API_URL}ambulance?longitude_btw=${southWest.lng}-${northEast.lng}&latitude_btw=${southWest.lat}-${northEast.lat}`)
          .then(res => res.json())
          .then(json => {
            this.markers = json.data;
          });
    },
    getAmbulanceInfo: async function(id) {
      await fetch(`${API_URL}info/${id}`)
          .then(res => res.json())
          .then(json => {
            this.ambulanceInfo = json.data;
            this.loadend = false;
          });
    }
  }
}
</script>

<style>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.marker-cluster-small {
  background-color: rgba(134, 135, 255, 0.6);
}

.marker-cluster-small div {
  background-color: rgba(134, 135, 255, 0.6);
}

.marker-cluster-medium {
  background-color: rgba(134, 135, 255, 0.6);
}

.marker-cluster-medium div {
  background-color: rgba(134, 135, 255, 0.6);
}

.marker-cluster-large {
  background-color: rgba(134, 135, 255, 0.6);
}

.marker-cluster-large div {
  background-color: rgba(134, 135, 255, 0.6);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
  background-color: rgb(134, 135, 255);
}

.leaflet-oldie .marker-cluster-small div {
  background-color: rgb(134, 135, 255);
}

.leaflet-oldie .marker-cluster-medium {
  background-color: rgb(134, 135, 255);
}

.leaflet-oldie .marker-cluster-medium div {
  background-color: rgb(134, 135, 255);
}

.leaflet-oldie .marker-cluster-large {
  background-color: rgb(134, 135, 255);
}

.leaflet-oldie .marker-cluster-large div {
  background-color: rgb(134, 135, 255);
}
</style>