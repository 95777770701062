<template>
  <form action="#" @submit.prevent="submitForm">
    <div class="form-row" :class="{err: this.formErrors.name}">
      <label class="lbl">
        <input type="text" name="name" v-model="formData.name" placeholder=" "/>
        <span class="form-label">Ваше ім`я</span>
      </label>
    </div>
    <div class="form-row" :class="{err: this.formErrors.email}">
      <label class="lbl">
        <input type="email" name="email" v-model="formData.email" placeholder=" "/>
        <span class="form-label">Email</span>
      </label>
    </div>
    <div class="form-row" :class="{err: this.formErrors.phone}">
      <label class="lbl">
        <input type="tel" name="phone" v-model="formData.phone" placeholder=" "/>
        <span class="form-label">Телефон</span>
      </label>
    </div>
    <div class="form-row" :class="{err: this.formErrors.message}">
      <label class="lbl">
        <textarea v-model="formData.message" name="message" placeholder=" "/>
        <span class="form-label">Текст повідомлення</span>
      </label>
    </div>
    <div class="btn-wrap">
      <button type="submit" class="btn btn-hover-effect">Надіслати</button>
    </div>
  </form>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import {API_URL, WEB_ROOT} from "../config";

Vue.use(VueToast);

export default {
  name: "ComplaintForm",
  props: ["info"],
  data() {
    return {
      formData: {
        name: '',
        email: '',
        phone: '',
        message: '',
        ambulance: this.info.legal_entity_name,
        address: this.info.registration_addresses,
        url: `${WEB_ROOT}?id=${this.info.legal_entity_id}`
      },
      formErrors: {
        name: false,
        email: false,
        phone: false,
        message: false
      },
      WEB_ROOT
    }
  },
  methods: {
    checkValidate() {
      const emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (this.formData.name === '') {
        this.formErrors.name = true;
        return false;
      } else {
        this.formErrors.name = false;
      }

      if (!this.formData.phone) {
        if (!emailPattern.test(this.formData.email)) {
          this.formErrors.email = true;
          return false;
        } else {
          this.formErrors.email = false;
        }
      }

      if (!this.formData.email) {
        if (this.formData.phone === '') {
          this.formErrors.phone = true;
          return false;
        } else {
          this.formErrors.phone = false;
        }
      }

      if (this.formData.message === '') {
        this.formErrors.message = true;
        return false;
      } else {
        this.formErrors.message = false;
      }

      return true;
    },
    submitForm() {
      if (this.checkValidate()) {
        fetch(`${API_URL}contact`,{
          method: "POST",
          body: JSON.stringify(this.formData)
        }).then((res) => {
          if (res.ok) {
            this.$emit('form-close');
            Vue.$toast.open({
              message: 'Ваше повідомлення успішно відправлено',
              position: 'top-right',
              type: 'success',
              duration: 5000
            });
          } else {
            Vue.$toast.open({
              message: 'Виникла помилка, спробуйте пізніше',
              position: 'top-right',
              type: 'error',
              duration: 5000
            });
          }
        });
      }
    }
  }
}
</script>